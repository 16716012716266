<template>
    <v-row>
    <v-col cols="12" sm="6">
    <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field class="centered-input"
                hide-details dense outlined clearable readonly clear-icon="mdi-close-circle-outline"
                :label="label"
                :ref="name"
                :placeholder="label"
                autocomplete="new-password"
                :value="newValue"
                v-bind="attrs"
                v-on="on"
                @click:clear="onClear($event)"
                @click="onClick($event)"
            ></v-text-field>
        </template>
        <v-date-picker :first-day-of-week="1" locale="de-de" scrollable show-adjacent-months show-week
            :value="dateValue"
            @input="onSubmit($event)"
        >
            <v-btn text color="warning" @click="onCancel()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="grey" disabled>{{ oldValue }}</v-btn>
        </v-date-picker>
    </v-dialog>
    </v-col>
    <v-col cols="12" sm="6">
	<v-text-field class="centered-input"
        hide-details dense outlined readonly disabled
        ref="vonbis" v-model="vonbis"
    ></v-text-field>
    </v-col>
    </v-row>
</template>
<script>

export default {
    name: 'KWDateInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        dialog: false,
    }),
    computed: {
        dateValue() {
            return this.getDateValue(this.value)
        },
        newValue() {
            return this.formatDate(this.dateValue)
        },
        oldValue() {
            return this.getOldValue(this.newValue)
        },
        vonbis() {
            return this.getRangeOfWeek(this.dateValue)
        },
    },
    mounted() {
    },
    methods: {
        formatDate (date) {
            if (!date || date.search("-") < 0) return null
            const [year, month, day] = date.split('-')
            const d = new Date(year, month - 1, day);
            const weekNumber = d.getWeekNumber()
            return weekNumber.week + '/' + weekNumber.year
        },
        getDateValue(date) {
            if (!date || date.search("-") < 0) return null
            const [year, month, day] = date.split('-')
            const dw = new Date(year, month - 1, day);
            const weekNumber = dw.getWeekNumber()
            const d = new Date().getDateOfISOWeek(weekNumber.week, weekNumber.year)
            const dl = new Date(d.getTime() - (d.getTimezoneOffset() * 60000))
            return dl.toISOString().substring(0, 10)
        },
        getOldValue(val) {
            return this.label + ': ' + (val ? val : '...')
        },
        onCancel() {
            this.dialog = false
        },
        onClear() {
            this.$emit('changed', null)
        },
        onClick() {
        },
        onSubmit(e) {
            console.log('picked:', e)
            const kwdate = this.getDateValue(e)
            this.$emit('changed', kwdate)
            this.dialog = false
        },
        getRangeOfWeek(date) {
            if (!date || date.search("-") < 0) return null
            const [year, month, day] = date.split('-')
            const d = new Date(year, month - 1, day);
            const weekNumber = d.getWeekNumber()
            const dw = new Date().getDateOfISOWeek(weekNumber.week, weekNumber.year)
            const dl = new Date(dw.getTime() - (dw.getTimezoneOffset() * 60000))
            const d7 = new Date(dl.valueOf())
            d7.setDate(d7.getDate() + 6)
            const dv = dl.toLocaleDateString("de-DE", { month: "2-digit", day: "2-digit", })
            const db = d7.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })
            return dv + ' - ' + db
        },
    },
    watch: {
    },
}
</script>
<style scoped>
.centered-input >>> input, label {
    text-align: center
}
</style>